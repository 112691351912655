<template>
  <div class="main">
    <van-form @submit="onSubmit">
      <van-field label="企业名称" label-width="120" :value="comName" disabled />
      <van-field label="当前充值币" label-width="120" :value="balanceTotal" disabled />
      <van-field label="充值币" label-width="120" v-model="query.amount" type="number" placeholder="请输入充值币" :rules="[{ required: true, message: '请填写充值币' }]" />
      <div class="btn">
        <van-button round block type="info" native-type="submit">充值</van-button>
      </div>
    </van-form>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { recharge, companyid } from '@/api/enterprise.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      comName: undefined, // 企业名称
      balanceTotal: undefined, // 总余额（充值币）
      query: {
        comId: undefined, // 企业id
        amount: undefined, // 充值金额
      }
    }
  },
  created() {
    this.companyid()
  },
  methods: {
    companyid() { // 获取管理员详细信息
      companyid(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.comName = res.data.data.info.comName
          this.balanceTotal = res.data.data.info.balanceTotal
        }
      })
    },
    onSubmit() {
      this.query.comId = this.$route.query.id
      recharge(this.query).then(res => {
        if (res.data.code === 200) {
          Toast('充值成功')
          this.$router.go(-1)
        }
      })
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.main {
  padding-bottom: 10px;
  .btn {
    padding: 7.5px 30px;
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-field__control:disabled {
  color: #333;
  -webkit-text-fill-color: #333;
}
</style>
